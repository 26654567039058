import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import styles from './ChannelsColumn.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    //faBroadcastTower,
    //faCommentDots,
    faThumbsDown,
    faThumbsUp,
    //faTv
} from '@fortawesome/free-solid-svg-icons';
import TextInput from 'src/Widgets/common/basicElements/TextInput/TextInput';
//@ts-ignore
import AutoSizer from 'react-virtualized-auto-sizer';
//@ts-ignore
import { FixedSizeList as List } from 'react-window';
import { useTranslation } from 'react-i18next';
import { MentionsVisualizerTypes } from '../../MentionsVisualizer.types';

//@ts-ignore
import tvIcon from 'src/assets/tv-symbol.svg';
//@ts-ignore
import radioIcon from 'src/assets/radio-symbol.svg';
//@ts-ignore
import webIcon from 'src/assets/print-symbol.svg';
//@ts-ignore
import tvIconActive from 'src/assets/tv-symbol-o.svg';
//@ts-ignore
import radioIconActive from 'src/assets/radio-symbol-o.svg';
//@ts-ignore
import webIconActive from 'src/assets/print-symbol-o.svg';
import APIChannels from 'src/API/APIChannels';
import useChannelIcons from 'src/hooks/useChannelIcons';

const ChannelsColumn = ({
    hasTypeInGroup,
    hasTypeInProfile,
    hasSentiment,
    handleTypeFilter,
    handleSentimentFilter,
    handleSearchChannels,
    handleChannelFilter,
    profileGroupContent,
    searchChannelsInput,
    setSearchChannelsInput,
    setFilteredChannelIcons,
    sortedUniqueChannels,
    activeFilter,
    //setActiveFilter,
    listKey,
    isEdited,
    isEditorial,
    filteredChannelIcons
}: MentionsVisualizerTypes.ChannelsColumnProps) => {
    const { t } = useTranslation();
    const listRef = useRef<List>(null);
    const [scrollOffset, setScrollOffset] = useState(0);

    const {findChannelIconsById} = useChannelIcons()

    // const [scrollToIndex, setScrollToIndex] = useState<number>(
    //     profileGroupContent.clips.findIndex(
    //         (clip: MentionsVisualizerTypes.Clip) =>
    //             clip.channelName === activeFilter.channels.slice(-1)[0]
    //     )
    // );

    //filter channels by profile
    useEffect(() => {
        if (!activeFilter.profile) {
            setFilteredChannelIcons(sortedUniqueChannels);
        } else {
            const uniqueChannels = Array.from(
                new Set(
                    profileGroupContent.clips
                        .filter((clip) => (isEditorial ? clip.profileID : clip.profileId) === activeFilter.profile)
                        .map((clip) => clip.channelName)
                )
            ).map((channelName) => {
                return profileGroupContent.clips.find(
                    (clip) => clip.channelName === channelName
                );
            });

            const sortedUniqueChannels = [...uniqueChannels].sort((a: any, b: any) => {
                if (a.channelName < b.channelName) {
                    return -1;
                }
                if (a.channelName > b.channelName) {
                    return 1;
                }
                return 0;
            });

            //setActiveFilter((prev) => ({ ...prev, countries: [] }));

            setFilteredChannelIcons(sortedUniqueChannels);
        }
    }, [activeFilter.profile, profileGroupContent.clips]);

    //channel search handler
    useEffect(() => {
        handleSearchChannels(searchChannelsInput);
    }, [searchChannelsInput]);

    //scroll to index when filter is changed
    const handleScroll = ({ scrollOffset }: any) => {
        setScrollOffset(scrollOffset);
    };

    //scroll to index when icon is clicked
    const handleIconClick = (index: number) => {
        if (listRef.current) {
            listRef.current.scrollTo(scrollOffset);
        }
    };

    //icon row component - react window
    const IconRow = ({ index, style }: { index: number; style: React.CSSProperties }) => {
        const clip = filteredChannelIcons[index];
        const icon = findChannelIconsById(clip.channelNumber);
        const isActive = activeFilter.channels.includes(clip.channelName);
        return (
            <div
                className={isActive ? styles.activeIconRow : styles.iconRow}
                style={style}
                onClick={() => {
                    handleChannelFilter(clip.channelName);
                    handleIconClick(index);
                }}
                data-testid={`channelRow-${clip.channelName}`}
            >
                <img
                    src={icon}
                    width="40"
                    height="20"
                    alt="icon"
                />
                <p className={styles.iconText}>{clip.channelName}</p>
            </div>
        );
    };

    return (
        <div className={styles.logoContainer}>
            <div className={styles.typeIconContainer}>
                {hasTypeInGroup('tv') &&
                    (!activeFilter.profile ||
                        hasTypeInProfile(activeFilter.profile, 'tv')) && (
                            <img data-testid={`channelType-tv`} className={
                                activeFilter.type === 'tv'
                                    ? styles.activeTypeIcon
                                    : styles.typeIcon
                            } width={25} src={activeFilter.type === 'tv' ? tvIconActive : tvIcon} alt="TV" onClick={() => handleTypeFilter('tv')} title={t('TV')} />
                    )}
                {hasTypeInGroup('radio') &&
                    (!activeFilter.profile ||
                        hasTypeInProfile(activeFilter.profile, 'radio')) && (
                        <img data-testid={`channelType-radio`} className={
                            activeFilter.type === 'radio'
                                ? styles.activeTypeIcon
                                : styles.typeIcon
                        } width={25} src={activeFilter.type === 'radio' ? radioIconActive : radioIcon} alt="Radio" onClick={() => handleTypeFilter('radio')} title={t('Radio')} />
                    )}
                {hasTypeInGroup('web') &&
                    (!activeFilter.profile ||
                        hasTypeInProfile(activeFilter.profile, 'web')) && (
                        <img data-testid={`channelType-web`} className={
                            activeFilter.type === 'web'
                                ? styles.activeTypeIcon
                                : styles.typeIcon
                        } width={25} src={activeFilter.type === 'web' ? webIconActive : webIcon} alt="Web" onClick={() => handleTypeFilter('web')} title={t('Web')} />
                    )}
                {isEdited && ' | '}
                {isEdited && (
                    <div className={styles.sentimentFiltersContainer}>
                        {hasSentiment('pos') && (
                            <FontAwesomeIcon
                                title={'pos'}
                                className={`${styles.sentiment} ${
                                    activeFilter.sentiment === 'pos'
                                        ? styles.activeSentiment
                                        : ''
                                }`}
                                size="1x"
                                icon={faThumbsUp}
                                data-testid="sentiment-icon"
                                onClick={() => handleSentimentFilter('pos')}
                            />
                        )}
                        {hasSentiment('neu') && (
                            <FontAwesomeIcon
                                title={'neu'}
                                className={`${styles.sentiment} ${
                                    activeFilter.sentiment === 'neu'
                                        ? styles.activeSentiment
                                        : ''
                                }`}
                                style={{ rotate: '-90deg' }}
                                size="1x"
                                icon={faThumbsUp}
                                data-testid="sentiment-icon"
                                onClick={() => handleSentimentFilter('neu')}
                            />
                        )}
                        {hasSentiment('neg') && (
                            <FontAwesomeIcon
                                title={'neg'}
                                className={`${styles.sentiment} ${
                                    activeFilter.sentiment === 'neg'
                                        ? styles.activeSentiment
                                        : ''
                                }`}
                                size="1x"
                                icon={faThumbsDown}
                                data-testid="sentiment-icon"
                                onClick={() => handleSentimentFilter('neg')}
                            />
                        )}
                    </div>
                )}
            </div>
            <hr className={styles.elementsDivider}></hr>
            <div className={styles.selectedChannelsCounter}>{`${
                activeFilter.channels.length
            } ${t('Channels')} ${t('Selected')}`}</div>
            <TextInput
                placeholder={t('Find Channels')}
                data-testid='channelsSearchInput'
                value={searchChannelsInput}
                onChange={(e: SyntheticEvent) =>
                    setSearchChannelsInput((e.target as HTMLInputElement).value)
                }
                className={styles.searchInputChannel}
            />

            <AutoSizer>
                {({ height, width }: { height: number; width: number }) => (
                    <List
                        key={listKey}
                        ref={listRef}
                        height={height - 110}
                        itemCount={filteredChannelIcons.length}
                        itemSize={40}
                        width={width}
                        onScroll={handleScroll}
                        initialScrollOffset={scrollOffset}
                    >
                        {IconRow}
                    </List>
                )}
            </AutoSizer>
            {filteredChannelIcons.length === 0 && (
                <div className={styles.noResults}>{t('No results for this query')}</div>
            )}
        </div>
    );
};

export default ChannelsColumn;
