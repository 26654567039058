import React, { Component } from 'react';
import styles from './SearchBar.module.scss';

// FontAwesome Base
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AutoComplete from '../TextInput/AutoComplete';
import TextInput from '../TextInput/TextInput';

// fontawesome icons
library.add(faSearch);

/**
 * simple searchbar that calls search() on every keystroke
 * 
 * props:
 * - placeholder: placeholder text to be shown
 * - searchTerm: prefilling for the searchbar, optional
 * - search(searchterm): gets called on keypress,
 *      also returns when search empties (with empty string)
 * - onEnter
 * - onFocus
 * - onBlur
 * - autoComplete: [boolean(default=false)] defines if AutoComplete component is rendered (or standard text field) - shows 'dropdown' with suggestions from backend that may be selected
 * - invalid: [boolean] indicating if there is any issue with the current input value
 */
export class SearchBar extends Component {
    static propTypes = {
        onEnter: PropTypes.func, // description of prop goes here        
    }
    constructor(props) {
        super(props);

        this.state = {
            input: this.props.searchTermPrefill || '',
            // searched: false,
        };

        this.onChange = this.onChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.searchTermPrefill !== this.props.searchTermPrefill) {
            this.setState({ input: this.props.searchTermPrefill });
        }
    }

    onChange(e) {
        const val = e.target.value;        
        this.setState({
            input: val,
            searched: false
        });
        this.props.search(val);
    }

    onKeyPress(e) {
        if (e.key === 'Enter' && typeof this.props.onEnter === 'function') {
            this.setState({searched: true});
            this.props.onEnter();
        }
    }

    render() {
        // translation method with fallback
        const t = this.props.t || (k => k);
        const { autoComplete } = this.props;

        return (
            <div className={styles.wrapper} style={this.props.style}>
                <FontAwesomeIcon icon="search" className={styles.icon} />
                {
                    autoComplete ?
                        <AutoComplete
                            hideSuggestions={this.state.searched}
                            inputRef={this.props.inputRef}
                            className={styles.autocomplete}
                            inputClassName={styles.input}
                            value={this.state.input}
                            onChange={this.onChange}
                            placeholder={this.props.placeholder || t('Search')}
                            onSelection={(suggestion, lastWord) => {
                                this.onChange({
                                    target: {
                                        value: this.state.input.replace(new RegExp(`${lastWord}$`,'g'),'') + suggestion
                                    }
                                })
                            }}
                            onKeyPress={this.onKeyPress}
                            onBlur={this.props.onBlur}
                            onFocus={this.props.onFocus}
                            invalid={this.props.invalid}
                        />
                        :
                            <div className={styles.autocomplete}>
                                <TextInput
                                    inputRef={this.props.inputRef}
                                    value={this.state.input}
                                    onChange={this.onChange}
                                    onKeyPress={this.onKeyPress}                        
                                    placeholder={this.props.placeholder || t('Search')}                    
                                    onBlur={this.props.onBlur}
                                    onFocus={this.props.onFocus}
                                    className={styles.input}
                                    invalid={this.props.invalid}
                                    data-testid={this.props['data-testid']}
                                />
                            </div>
                }
            </div>
        );
    }
}

export default withTranslation()(SearchBar);
