import React from 'react';
import styles from './SearchHeader.module.scss';
import SearchBar from '../../common/basicElements/SearchBar/SearchBar';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSave, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from 'src/Widgets/common/basicElements/Button/Button';
library.add(faSave, faPlus);

class SearchHeader extends React.Component {
    static propTypes = {
        onButtonClick: PropTypes.func.isRequired,  // onClick action for profile button
        buttonDisabled: PropTypes.bool,            // disable profile button
        inputRef: PropTypes.func,
        onQueryChange: PropTypes.func,
        search: PropTypes.func,
        query:PropTypes.string,
        queryInvalid: PropTypes.bool, // indicating if there is any issue with the query (e.g. invalid syntax)
        showAddProfile: PropTypes.bool, // decide wheter we show 'add profile' button or not (e.g. when seeing search because clicking coverage numbers, we don't need it)
    };
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        // translation method with fallback
        const t = this.props.t || (k => k);

        return (
            <div className={styles.header}>
                <SearchBar
                    inputRef={this.props.inputRef}
                    className={styles.SearchBar}
                    search={this.props.onQueryChange}
                    onEnter={this.props.searchDisabled ? undefined : this.props.search}
                    searchTermPrefill={this.props.query}
                    invalid={this.props.queryInvalid}
                    onBlur={this.props.onInputBlur}
                    onFocus={this.props.onInputFocus}
                    autoComplete={true}
                    style={{zIndex:9002}}
                    data-testid={'search-bar'}
                />
                {this.props.showAddProfile && <Button
                    data-tut="tour__search_add_profile"
                    onClick={this.props.onButtonClick}
                    type='primary'
                    btnClass={styles.addProfileBtn}
                    disabled={this.props.buttonDisabled}
                    title={this.props.buttonTitle}
                >
                    <FontAwesomeIcon icon="plus" /> {t('Profile')}
                </Button>}
            </div>
        );
    }
}
export default withTranslation()(SearchHeader);
